.redirect-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-top: -100px;
  font-size: 22px;

  a {
    margin-left: 15px;
  }

  img {
    margin-top: 20px;
  }
}