@import "../../styles/colors";

.setting-group-title {
  display: flex;
  align-items: center;

  &::before {
    content: "";
    width: 40px;
    border-bottom: solid 1px $grey-7;
    margin-right: 10px;
  }

  &::after {
    content: "";
    flex-grow: 1;
    border-bottom: solid 1px $grey-7;
    margin-left: 10px;
  }
}