@import "../../../styles/colors";

.step-wrapper {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;

  .button {
    color: $grey-3;
    font-size: 1.2em;
    border-radius: 0;
  }
}
