@import "../../../styles/colors";

.image-input-wrapper {
  width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;

  .drop-zone {
    min-height: 150px;
  }

  .divider {
    margin: 15px 0;
    font-weight: 400;
    font-size: 1.2em;
    color: $grey-3;
  }

  .link-input {
    width: 100%;
  }
}