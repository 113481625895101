@import "../../styles/colors";

.documentation {
  font-size: 16px;
  font-weight: 400;
  color: $grey-3;
  line-height: 22px;
  padding-right: 150px;
  //font-family: Arial;

  .sponsor {
    margin-left: 40px;
    &.tier-1 {
      font-size: 36px;
      color: $grey-1;
      line-height: 38px;
    }
    &.tier-2 {
      font-size: 30px;
      color: $grey-2;
      line-height: 30px;
    }
    &.tier-3 {
      font-size: 22px;
      color: $grey-4;
      line-height: 22px;
    }
    &.tier-4 {
      font-size: 16px;
      color: $grey-5;
      line-height: 18px;
    }
    &.tier-5 {
      font-size: 12px;
      color: $grey-6;
      line-height: 14px;
    }
  }

  div {
    margin-bottom: 15px;
  }

  li {
    margin-top: 15px;

    div {
      margin-bottom: 6px;
    }
  }

  ul>li {
      list-style-type: disc;
    }

  h2 {
    //font-weight: 300;
    font-size: 26px;
    color: $grey-2;

    &:first-child {
      margin-top: 0;
    }
  }
}