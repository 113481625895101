@import "colors";

.page-container {
  display: flex !important;
  flex-direction: column;
  height: 100vh;
  padding: 50px 100px;
  padding-bottom: 0 !important;

  &-content {
    display: flex;
    height: 100%;

    &.centralize {
      align-items: center;
      justify-content: center;
    }
  }
}

.page-title {
  margin-bottom: 25px;
}

.base-icon {
  width: 24px;
  height: 24px;

  &.twitch, path, rect {
    fill: $twitch;
  }

  &.da path {
    fill: $da;
  }
}

.hint {
  position: relative;
  top: -5px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 300;
  width: 490px;
  color: $grey-5;
  letter-spacing: 0.8px;
}

.error-hint {
  color: $red-3 !important;
}

.MuiFormControlLabel-root {
  align-items: start !important;
}

.wheel-selector {
  position: absolute;
  margin-top: calc(58px + 2px);
  margin-left: -23px;
  height: 46px;
  width: 64px;
  clip-path: polygon(50% 50%, 0 0, 100% 0);
  background-color: $grey-10;
  border: 3px solid #eee;
}

.wheel-winner {
  position: absolute;
  top: 58px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  //background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 50%, rgba(255,255,255,0) 100%);
  //background-color: rgba(#000, 0.6);

  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 40px;
  font-family: Arial;
  background: radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(0,0,0,0.65) 40%, rgba(0,0,0,0.3) 68%, rgba(0,0,0,0) 72%);
}

.wheel-target {
  text-align: center;
  font-size: 35px;
  font-weight: 400;
  color: $grey-2;
  margin-bottom: 20px;
}

.chart {
  width: 730px;
  height: 360px;
  padding: 0 10px 10px 10px;
  border-radius: 5px;
  border: 3px solid $blue-gray-2;
  background-color: rgba($blue-gray-5, .3);
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;

  .col {
    display: flex;
    align-items: center;

    & > * {
      margin-right: 10px !important;
    }
  }
}



.description-dialog {
  &.dropout {
    .description-content-dropout {
      height: 100vh;
    }
  }

  &-content {
    color: $grey-4;
    line-height: 20px;
    font-family: 'Roboto', 'Helvetica Neue', Helvetica, "Roboto Light", sans-serif;

    div {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}