@import "../../../styles/colors";

.purchase {
  margin-bottom: 10px;
  cursor: pointer;
  border: 3px solid transparent;
  padding-bottom: 0;

  &.disabled {
    pointer-events: none;
    cursor: initial;
  }

  .purchase-content {
    padding-bottom: 10px !important;
  }

  &-new-button {
    width: 100%;
    background-color: rgba(#fff, .1) !important;
    border-color: rgba(#fff, .4) !important;
    margin-top: 10px !important;
    font-size: 12px !important;

    &:hover {
      background-color: rgba(#fff, .2) !important;
    }
  }

  &.remove-cost {
    border: solid 3px $red-3;
  }

  &.drag-placeholder {
    border: 3px dashed $grey-4;
    background-color: transparent;

    .purchase-content {
      opacity: 0;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h6 {
      font-weight: 400;
    }

    &-remove-button {
      padding: 5px !important;
    }
  }
}

.drag-context {
  .purchase {
    width: 304px;
  }
}