.auc-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 1.5em 2em 0.5em 2em !important;

  &.centralize {
    align-items: center;
    justify-content: center;
  }

  &-row {
    flex-grow: 1;
    display: flex;
    height: 100%;
    min-height: 0;
    min-width: 0;
    gap: 0.5em;
  }

  &-column {
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 0;
    min-width: 0;
    gap: 0.5em;
  }
}