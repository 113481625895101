.purchase-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .purchase-list {
    flex-grow: 1;
    height: 0;
    overflow: auto;
    width: 310px;
  }

  .total-purchases {
    margin-top: 10px;

    span {
      margin-left: 5px;
    }
  }
}
