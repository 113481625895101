.roulette-menu {
  &-presets {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .MuiButton-label {
      text-transform: none !important;
      letter-spacing: .05em;
    }
  }
}